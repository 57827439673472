// components
import Card from '../components/Card';

// hooks
import { useFetch } from '../hooks/useFetch';

export default function Projects() {
    const {data: projects} = useFetch("/content/items/projects");

    if (!projects) 
        return <p></p>;

    return (
        <div>
            <h1><strong>Referenzen</strong></h1>

            {projects.map(project => (
                <Card 
                key={project.name} 
                name={project.name} 
                description={project.description} 
                img={process.env.REACT_APP_BACKEND + "/assets/link/" + project.image._id}
            />
            ))}
        </div>
    )
}