import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// styles
import "./styles/index.css"

// routes
import Root from "./routes/Root";
import ErrorPage from "./routes/ErrorPage";
import Home from "./routes/Home";

// contexts
import { ThemeProvider } from './contexts/ThemeContext';
import Projects from './routes/Projects';
import Zertifikate from './routes/Zertifikate';
import Impressum from './routes/Impressum';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <Root>
        <ErrorPage /> 
      </Root>
    ),
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/referenzen",
        element: <Projects />
      },
      {
        path: "/zertifikate",
        element: <Zertifikate />
      },
      {
        path: "/impressum",
        element: <Impressum />
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router}/>
    </ThemeProvider>
  </React.StrictMode>
);