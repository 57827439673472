export default function Impressum({ children }) {
    return (
        <>
            <h1>Impressum</h1>
            <h2 id="m46">Diensteanbieter</h2>
            <p>Stefan Selch</p>
            <p>Glockenstraße 9</p>
            <p>95447 Bayreuh</p>
            <p>Deutschland</p>
            
            <h2 id="m56">Kontaktmöglichkeiten</h2><p>E-Mail-Adresse: <a href="mailto:stefan@selch.de">stefan@selch.de</a></p>
            
            <p className="seal"><a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
        </>
    );
}