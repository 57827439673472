// components
import Card from '../components/Card';

// hooks
import { useFetch } from '../hooks/useFetch';

export default function Zertifikate() {
    const {data: zertifikate} = useFetch("/content/items/zertifikate");

    if (!zertifikate) 
        return <p></p>;

    return (
        <div>
            <h1><strong>Zertifikate</strong></h1>

            {zertifikate.map(zertifikat => (
                <Card 
                key={zertifikat.name} 
                name={zertifikat.name} 
                description={zertifikat.description} 
                img={process.env.REACT_APP_BACKEND + "/assets/link/" + zertifikat.image._id}
            />
            ))}
        </div>
    )
}