import React from "react";
import * as motion from "motion/react-client"

export default function Card({name, description, img}) {
  return (
    <motion.div
      className="p-10"
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{
        opacity: 1,
        scale: 1,
        transition: { type: "spring", duration: 0.8 }
      }}
      viewport={{ once: true, amount: 0.5 }}
    >
      <CardHorizontal name={name} description={description} img={img}/>
    </motion.div>
  )
}

function CardHorizontal({name, description, img}) {
    return (
      <>
        {/*<!-- Component: Horizontal card--> */}
        <div className="flex flex-col overflow-hidden bg-white rounded shadow-md text-slate-500 shadow-slate-200 sm:flex-row">
          {/*  <!-- Image --> */}
          <figure className="flex-1">
            <img
              src={img}
              alt="card image"
              className="object-cover min-h-full aspect-auto"
            />
          </figure>
          {/*  <!-- Body--> */}
          <div className="flex-1 p-6 sm:mx-6 sm:px-0">
            <header className="flex gap-4 mb-4">
             
              <div>
                <h3 className="text-xl font-medium text-slate-700">
                  {name}
                </h3>
                
              </div>
            </header>
            <p>
              {description}
            </p>
          </div>
        </div>
        {/*<!-- End Horizontal card--> */}
      </>
    )
  }
  