import React from 'react';


// components
import Accordion, {AccordionItem} from '../components/Accordion'; 
import Feed, {FeedItem} from '../components/Feed';

// hooks
import { useFetch } from '../hooks/useFetch';

export default function Home() {
    const {data: home} = useFetch("/content/item/home");

    if (!home) 
      return <p></p>;

    return (
      <>
        <h1><strong>Über Mich</strong></h1>
        <p>{home.about.description}</p>
        <br/>

        <Feed>
          {home.feed.map(feed => (
            <FeedItem key={feed.name} name={feed.name} description={feed.description} year={feed.year} icon={feed.icon}/>
          ))}
        </Feed>

      

        <br/>

        <h1><strong>Dienstleistungen</strong></h1>
        <Accordion>
          {home.services.map(service => (
            <AccordionItem key={service.name} name={service.name} open={service == home.services[0]} description={service.description} icon={service.icon}/>
          ))}
        </Accordion>
        <br/>
      </>
    );
}

